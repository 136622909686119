import React from "react";
import * as Sentry from "@sentry/react";

import { LoggedInAuth } from "../hooks/useAuth";
import { getToken } from "../lib/api/token";
import BrandingHeader, {
  getHydroforecastIcon,
  getLensIcon,
} from "../lib/components/Branding";
import {
  LENS_BASE_URL,
  HYDROFORECAST_BASE_URL,
  isLensUrl,
  isHydroForecastUrl,
  getUrlOrigin,
  ERROR_TYPES,
  isSignupUrl,
} from "../lib/utils";
import Loading from "./Loading";

import * as tw from "../lib/tailwindClasses";
import { Navigate, useNavigate } from "react-router-dom";

type Props = {
  auth: LoggedInAuth;
};

export function InlineAppSwitch() {
  const buttonClass =
    "w-32 rounded-md p-2 text-sm shadow-[0_3px_6px_rgb(0,0,0,0.1)] hover:bg-slate-200 cursor-pointer focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600";

  return (
    <div className="flex justify-center space-x-4 my-4">
      <a href={`${LENS_BASE_URL}/signIn`} className={buttonClass}>
        <img
          className="mx-auto h-10 w-10 mb-2"
          src={getLensIcon()}
          alt="Go to Lens"
        />
        <div className="font-normal text-slate-900">Lens</div>
      </a>
      <a href={`${HYDROFORECAST_BASE_URL}/signIn`} className={buttonClass}>
        <img
          className="mx-auto h-10 w-10 mb-2"
          src={getHydroforecastIcon()}
          alt="Go to Hydroforecast"
        />
        <div className="font-normal text-slate-900">Hydroforecast</div>
      </a>
    </div>
  );
}

export default function AppSwitch({ auth }: Props) {
  const [canRedirect, setCanRedirect] = React.useState(false);
  const [isRedirecting, setIsRedirecting] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [signInMethods, setSignInMethods] = React.useState<string[] | null>(
    null,
  );
  const [getSignInMethodsError, setGetSignInMethodsError] = React.useState<
    string | null
  >(null);
  const [ssoError, setSsoError] = React.useState<string | null>(null);
  const navigate = useNavigate();

  const hasLens = auth.profileOrganization?.products.includes("Lens");
  const hasHydroForecast =
    auth.profileOrganization?.products.includes("HydroForecast");
  const ssoRequired =
    auth.profileOrganization?.settings.authentication_config?.sso_required;

  const searchParams = new URLSearchParams(window.location.search);
  const redirectUrlParam = searchParams.get("redirect");

  // On load, fetch the logged in user's available sign in methods
  React.useEffect(() => {
    const getAndSetSignInMethods = async () => {
      try {
        const methods = await auth.getSignInMethods();
        setSignInMethods(methods);
      } catch (e) {
        const error = (e as { code: string }).code;
        console.error("Unable to get sign in methods", error);
        Sentry.captureException(e);
        setGetSignInMethodsError(error);
      }
    };
    getAndSetSignInMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (auth.profileOrganization) {
      const ssoProviderId =
        auth.profileOrganization.settings.authentication_config?.provider_id;
      if (
        !ssoProviderId ||
        (signInMethods && signInMethods.includes(ssoProviderId))
      ) {
        setCanRedirect(true);
      } else if (signInMethods) {
        setIsLoading(false);
      }
    }
  }, [auth.profileOrganization, setCanRedirect, signInMethods]);

  const isValidRedirectUrl = React.useCallback(
    (url: string) => {
      // Check that the url origin is a valid Upstream origin, to
      // prevent redirecting to an external domain.
      // For example: shortens "https://app.upstream.tech/org-id/projects"
      // to "https://app.upstream.tech" before checking against our set of valid urls.
      const urlOrigin = getUrlOrigin(url);
      return (
        urlOrigin &&
        ((isLensUrl(urlOrigin) && hasLens) ||
          (isHydroForecastUrl(urlOrigin) && hasHydroForecast) ||
          isSignupUrl(new URL(url)))
      );
    },
    [hasHydroForecast, hasLens],
  );

  const retrieveTokenAndRedirect = async (redirectUrl: string) => {
    setIsRedirecting(true);
    const token = await getToken();
    const urlWithToken = new URL(redirectUrl);
    urlWithToken.searchParams.set("token", token);
    window.location.href = urlWithToken.href;
  };

  // Set redirectUrl if user only has one app
  // or if a redirectUrlParam was provided. We want this to run once, as
  // other useEffect will handle state transitions.
  React.useEffect(() => {
    if (canRedirect && !isRedirecting) {
      const searchParams = new URLSearchParams(window.location.search);
      const redirectUrlParam = searchParams.get("redirect");

      if (redirectUrlParam && isValidRedirectUrl(redirectUrlParam)) {
        retrieveTokenAndRedirect(redirectUrlParam);
      } else if (hasLens && hasHydroForecast) {
        setIsLoading(false);
      } else if (hasLens) {
        retrieveTokenAndRedirect(LENS_BASE_URL);
      } else if (hasHydroForecast) {
        retrieveTokenAndRedirect(HYDROFORECAST_BASE_URL);
      }
    }
  }, [
    canRedirect,
    isRedirecting,
    hasHydroForecast,
    hasLens,
    isValidRedirectUrl,
  ]);

  if (redirectUrlParam && isSignupUrl(new URL(redirectUrlParam))) {
    const { pathname, search } = new URL(redirectUrlParam);
    // Redirect signups to the signup page
    return <Navigate to={`${pathname}${search}`} />;
  }

  const getErrorContent = (
    errorType: string,
    buttonLabel: string,
    buttonAction: () => void,
  ): React.ReactElement => (
    <div className={`${tw.spacedSection} text-center`}>
      <BrandingHeader productUrl={redirectUrlParam} />
      <div className={tw.errorText}>{ERROR_TYPES[errorType]}</div>
      <button type="button" className={tw.buttonPrimary} onClick={buttonAction}>
        {buttonLabel}
      </button>
    </div>
  );

  const signOutCallback = () => {
    auth.signOut();
    navigate("/");
  };

  let content;
  if (!auth.profileOrganization) {
    // if there is no organization attatched to the user,
    // let them know and give them the option to sign out.
    // Users in this state can still accept invites to orgs.
    content = getErrorContent(
      "auth/no-organizations",
      "Sign out",
      signOutCallback,
    );
  } else if (getSignInMethodsError) {
    content = auth.profileOrganization ? (
      <Loading />
    ) : (
      getErrorContent("unknown", "Sign out", signOutCallback)
    );
  } else if (isLoading) {
    content = <Loading />;
  } else if (canRedirect) {
    content = (
      <div className={`${tw.spacedSection} text-center`}>
        <BrandingHeader productUrl={redirectUrlParam} />
        <button
          type="button"
          className={tw.buttonPrimary}
          onClick={() => retrieveTokenAndRedirect(HYDROFORECAST_BASE_URL)}
          disabled={isRedirecting}
        >
          Go to HydroForecast
        </button>
        <br />
        <br />
        <button
          type="button"
          className={tw.buttonPrimary}
          onClick={() => retrieveTokenAndRedirect(LENS_BASE_URL)}
          disabled={isRedirecting}
        >
          Go to Lens
        </button>
      </div>
    );
  } else {
    content = (
      <div className={`${tw.spacedSection} text-center`}>
        <BrandingHeader productUrl={redirectUrlParam} />

        <div>
          {ssoRequired
            ? "Your organization requires Single Sign On"
            : "Your organization supports Single Sign On"}
        </div>
        <button
          type="button"
          className={tw.buttonPrimary}
          onClick={async () => {
            try {
              setSsoError(null);
              await auth.linkSSO();
            } catch (e) {
              const error = (e as { code: string }).code;
              console.error("Unable to link SSO", error);
              setSsoError(error);
            }
          }}
        >
          {ssoRequired ? "Setup SSO" : "Setup SSO now"}
        </button>
        {ssoError && (
          <div className={tw.errorText}>{ERROR_TYPES["unknown"]}</div>
        )}
        {!ssoRequired && (
          <button
            type="button"
            className={tw.buttonSecondary}
            onClick={() => {
              setIsLoading(true);
              setCanRedirect(true);
            }}
          >
            Setup SSO later
          </button>
        )}
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center h-screen w-full">
      {content}
    </div>
  );
}
